// GRANDBROTHERS SKIN <- BLUMFELD SKIN <- JIRAFA SKIN
$body-background: $body-bg;
body,
.product-content {
  background: $body-background;
  background-size: cover;
  background-attachment: fixed;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

// NAV

#header {
  .navbar-light {
    background: $body-bg url('./assets/images/background.jpg') no-repeat center center;
    background-size: cover;
  }
}

#header .navbar .navbar-brand {
  img {
    height: 2.5rem;
    max-height: 2.5rem;

    @include media-breakpoint-up(md) {
      height: 3.5rem;
      max-height: 3.5rem;
    }

    &:hover,
    &:focus {
      filter: opacity(0.66);
    }
  }
}

.navbar .hamburger {
  @include media-breakpoint-down(sm) {
    padding-left: 0px;
  }
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.offcanvas-collapse {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-500;
      font-style: italic;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: #fff, $alpha: 0.9) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki {
  .card-title {
    text-align: center;

    .sub-title {
      font-size: smaller;
    }
  }

  // .maki-img-container {
  //   border: 1px solid $dark;
  //   box-shadow: $box-shadow;
  // }
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

// .blurry-background-image {
//   background-color: transparent;
// }

// .blurry-background-image:before {
//   background-image: none;
// }

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  // background-color: $body-bg;
  color: $primary;
  border-bottom: 10px solid $primary;
}

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $gray-300;
  .nigiri-content {
    padding: 1.25rem 0;
    padding-top: 0;
    margin-top: 0.2rem;
    color: $primary;
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
    //color: $white;
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    text-transform: uppercase;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 0.8em;
  }
  .title {
    font-size: 0.6em;
    margin-bottom: 0;
    //color: $white;
    font-family: $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: 0.5em;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-family: $font-family-sans-serif !important;
    font-size: $h3-font-size !important;
    //color: $jorisred;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
}

// PDP

body.ticket-detail-page {
  .card {
    border: 0;
  }

  .tt-sticky-top {
    top: auto;
  }
  .card.article-title {
    background-color: $primary;
    h1 {
      color: $white;
      font-size: $h1-font-size;
      small {
        color: $white;
      }
    }
  }
  .product-info-bar {
    //i {color: $agorange !important;}
    box-shadow: none;
    background: $body-bg url('./assets/images/background.jpg') no-repeat center center;
    background-size: cover;
  }
}

.detail-page {
  h1 {
    margin-top: 0;
    //color: $jorisred;
    text-transform: uppercase;
    font-size: $h2-font-size;
    //.sub-title {color: $jorisblue;}
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: $gray-100;
}

// MAKI

// .maki {
//   @extend .p-3;
//   .card-title {
//     text-align: left;
//     .title {
//       text-transform: uppercase;
//       font-size: $h5-font-size;
//       color: $primary;
//       @extend .text-truncate;
//     }

//     .sub-title {
//       font-size: smaller;
//       @extend .mb-2;
//     }
//   }
//   &:hover {
//     background-color: $gray-100;
//     transition: all .15s ease-in-out;
//   }
// }

// fix band name on ticket detail getting wrapped on iPhone
@media (max-width: 575.98px) {
  .title {
    font-size: 0.7em;
  }
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        border-top: 0;
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// header image fixes
.header-image {
  min-height: 400px;
  object-fit: cover;
}
